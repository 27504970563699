@media print {
  body {
    zoom: 70%;
  }

  #reveil {
    page-break-before: always;
  }
}

.patient {
  margin-top: 100px;
  margin-left: 10px;
  margin-right: 10px;
  border-radius: 20px;
  background-color: white;
  padding: 20px;
  height: 55px;
}

.bordsup {
  position: fixed;
  top: 0;
  left: 0;
  height: 40px;
  width: 100%;
  background-color: #7121A3;
  z-index: 2;
}

.induction {
  margin: 20px;
}

.ligne {
  height: 2px;
  border-width: 0;
  background-color: #7121A3;
  margin: 0px;
}

.tendances {
  background-color: white;
  padding: 12px;
}

.entete {
  width: 100%;
  height: 30px;
  display: flex;
  padding-top: 10px;
  margin-left: 0px;
}

.slider {
  width: 100%;
  height: 30px;
  display: flex;
  margin-left: 0px;
}

.reveil {
  padding: 20px;
}